import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap"
import LoaderButton from "../../components/LoaderButton"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import { useHistory } from "react-router-dom"
import "./SignIn.css"

const SignIn = props => {
  useEffect(() => {
    document.title = "Boditel | Sign In"
  }, [])

  const history = useHistory()

  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  const [fields, handleFieldChange] = useFormFields({
    username: "",
    password: "",
  })
  const [isChanging, setIsChanging] = useState(false)

  const failedToSignIn = "Failed to sign in"

  function validateForm() {
    return fields.username.length > 0 && fields.password.length > 0
  }

  async function handleChangeClick(event) {
    event.preventDefault()
    setShowAlert(false)

    setIsChanging(true)

    try {
      // Sign in using the Auth module from Amplify
      const username = fields.username
      const password = fields.password
      await Auth.signIn({ username, password })

      // Redirect the user to the profile page
      history.push("/profile")
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToSignIn)
      setAlertBody(error.message)

      setIsChanging(false)
    }
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>
      <div className="signin-div">
        <Form onSubmit={handleChangeClick}>
          <h3>Sign In</h3>
          <FormGroup size="large" controlId="username">
            <FormLabel>Email *</FormLabel>
            <FormControl
              autoFocus
              type="text"
              onChange={handleFieldChange}
              value={fields.username}
              placeholder="Email"
            />
          </FormGroup>
          <FormText className="text-muted">Email = Username</FormText>
          <br />
          <FormGroup size="large" controlId="password">
            <FormLabel>Password *</FormLabel>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.password}
              placeholder="Password"
            />
          </FormGroup>
          <br />
          <LoaderButton
            type="submit"
            size="large"
            disabled={!validateForm()}
            isLoading={isChanging}
          >
            Sign In
          </LoaderButton>
        </Form>
      </div>
    </Container>
  )
}

export default SignIn
