import React, { useState, useEffect } from "react"
import { Route, BrowserRouter, Switch } from "react-router-dom"
import "./App.css"
import { Amplify } from "aws-amplify"
// import { Amplify, API, Storage } from "aws-amplify"
import { AmplifyAuthenticator } from "@aws-amplify/ui-react"
// import Auth from "@aws-amplify/auth"
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components"
import awsconfig from "./aws-exports"

// Bootstrap
// import { Container } from "react-bootstrap"

// import Header from "./components/layout/header/Header"
import Footer from "./components/layout/footer/Footer"
import MainNavbar from "./components/layout/navbar/MainNavbar"
import MainNavbarSignedIn from "./components/layout/navbar/MainNavbarSignedIn"

// import * as Constants from "./pages/Constants"

// TOP NAVBAR

// Corporate
import About from "./pages/corporate/About"
import Privacy from "./pages/corporate/Privacy"
import Terms from "./pages/corporate/Terms"

// Account
import SignUp from "./pages/account/SignUp"
import SignIn from "./pages/account/SignIn"
import SignOut from "./pages/account/SignOut"
import Profile from "./pages/account/Profile"
import ChangeEmail from "./pages/account/ChangeEmail"
import ChangePhone from "./pages/account/ChangePhone"
import ChangePassword from "./pages/account/ChangePassword"
import ResetPassword from "./pages/account/ResetPassword"

// Home
import Home from "./pages/home/Home"

// GraphQL
// import { listNotes } from "./graphql/queries"
// import {
//   createNote as createNoteMutation,
//   deleteNote as deleteNoteMutation,
// } from "./graphql/mutations"

Amplify.configure(awsconfig)

// const initialFormState = { name: "", description: "" }

function App() {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  // Note Variables
  // const [notes, setNotes] = useState([])
  // const [formData, setFormData] = useState(initialFormState)

  // On load, perform actions (i.e. fetch notes)
  useEffect(() => {
    return (
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState)
        setUser(authData)
      }),
      console.log("...")
      // fetchNotes()
    )
  }, [])

  // FUNCTIONS

  // Uses the API class to send a query to the GraphQL API and retrieve a list of notes.
  // async function fetchNotes() {
  //   const apiData = await API.graphql({ query: listNotes })
  //   const notesFromAPI = apiData.data.listNotes.items
  //   await Promise.all(
  //     notesFromAPI.map(async note => {
  //       if (note.image) {
  //         const image = await Storage.get(note.image)
  //         note.image = image
  //       }
  //       return note
  //     })
  //   )
  //   setNotes(apiData.data.listNotes.items)
  // }

  // Uses the API class to send a mutation to the GraphQL API and passes in the variables
  // needed for a GraphQL mutation so that we can create a new note with the form data.
  // async function createNote() {
  //   if (!formData.name || !formData.description) return
  //   await API.graphql({
  //     query: createNoteMutation,
  //     variables: { input: formData },
  //   })
  //   if (formData.image) {
  //     const image = await Storage.get(formData.image)
  //     formData.image = image
  //   }
  //   setNotes([...notes, formData])
  //   setFormData(initialFormState)
  // }

  // Sends a GraphQL mutation along with some variables to delete a note.
  // async function deleteNote({ id }) {
  //   const newNotesArray = notes.filter(note => note.id !== id)
  //   setNotes(newNotesArray)
  //   await API.graphql({
  //     query: deleteNoteMutation,
  //     variables: { input: { id } },
  //   })
  // }

  // Handles the image upload
  // async function onChange(e) {
  //   if (!e.target.files[0]) return
  //   const file = e.target.files[0]
  //   setFormData({ ...formData, image: file.name })
  //   await Storage.put(file.name, file)
  //   fetchNotes()
  // }

  // Auth.currentAuthenticatedUser().then((user) => {
  //   console.log('user email = ' + user.attributes.email);
  // });

  return authState === AuthState.SignedIn && user ? (
    <AmplifyAuthenticator usernameAlias="email">
      <BrowserRouter>
        <div
          className="App"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flex: 1,
          }}
        >
          <MainNavbarSignedIn />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />

            {/* Route authenticated links */}
            <Route path="/signout" component={SignOut} />
            <Route path="/profile" component={Profile} />
            <Route path="/change-email" component={ChangeEmail} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/change-phone" component={ChangePhone} />

            {/* Route blocked to Profile  */}
            <Route path="/signup" component={Profile} />
            <Route path="/signin" component={Profile} />
            <Route path="/reset-password" component={Profile} />
          </Switch>

          {/* <input
            onChange={e => setFormData({ ...formData, 'name': e.target.value})}
            placeholder="Note name"
            value={formData.name}
          />
          <input
            onChange={e => setFormData({ ...formData, 'description': e.target.value})}
            placeholder="Note description"
            value={formData.description}
          />
          <input
            type="file"
            onChange={onChange}
          />
          <button onClick={createNote}>Create Note</button>
          <div style={{marginBottom: 30}}>
            {
              notes.map(note => (
                <div key={note.id || note.name}>
                  <h2>{note.name}</h2>
                  <p>{note.description}</p>
                  <button onClick={() => deleteNote(note)}>Delete note</button>
                  {
                    note.image && <img src={note.image} style={{width: 400}} />
                  }
                </div>
              ))
            }

          </div> */}

          <Footer />
        </div>
      </BrowserRouter>
    </AmplifyAuthenticator>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <BrowserRouter>
        <div
          className="App"
          slot="sign-in"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flex: 1,
          }}
        >
          <MainNavbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />

            {/* Route authenticated links */}
            <Route path="/signup" component={SignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/reset-password" component={ResetPassword} />

            {/* Route blocked to Sign In  */}
            <Route path="/signout" component={SignIn} />
            <Route path="/profile" component={SignIn} />
            <Route path="/change-email" component={SignIn} />
            <Route path="/change-password" component={SignIn} />
            <Route path="/change-phone" component={SignIn} />
          </Switch>

          <Footer />
        </div>
      </BrowserRouter>
    </AmplifyAuthenticator>
  )
}

export default App
