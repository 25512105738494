// This functional component asks a user to enter their new password and click the Send Confirmation
// button. Cognito sends them a verification code. User receives the code via email and then enters
// the code along with the new password. Component then confirms that their password has been changed.

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap"
import LoaderButton from "../../components/LoaderButton"
import {
  passwordPolicy,
  hasNumber,
  hasSpecialCharacter,
  hasLowerCase,
  hasUpperCase,
} from "../../libs/validationLib"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import "./ResetPassword.css"

const ResetPassword = props => {
  useEffect(() => {
    document.title = "Boditel | Reset Password"
  }, [])

  // Alert Getter/Setters
  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  // Form Field Getter/Setters
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  })
  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  const failedToResetPassword = "Failed to reset password"

  function validateCodeForm() {
    return fields.email.length > 0
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.confirmPassword.length > 0
    )
  }

  function validateFormForErrorAlerting() {
    if (fields.password === fields.oldPassword)
      throw new Error(
        "The new password must be different from the old password."
      )
    if (fields.password.length < 8)
      throw new Error(
        "The new password must be at least 8 characters in length."
      )
    if (!hasNumber(fields.password))
      throw new Error("The new password must contain at least one number.")
    if (!hasSpecialCharacter(fields.password))
      throw new Error(
        "The new password must contain at least one special character."
      )
    if (!hasLowerCase(fields.password))
      throw new Error(
        "The new password must contain at least one lowercase letter."
      )
    if (!hasUpperCase(fields.password))
      throw new Error(
        "The new password must contain at least one uppercase letter."
      )
    if (fields.password !== fields.confirmPassword)
      throw new Error("The new password and confirm password must match.")
  }

  async function handleSendCodeClick(event) {
    event.preventDefault()
    setShowAlert(false)

    // Flag that the reset password code is being sent
    setIsSendingCode(true)

    try {
      // Send a verification code to specified user's email using the Auth module from Amplify
      await Auth.forgotPassword(fields.email)

      // Flag that the reset password code has now been sent
      setCodeSent(true)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToResetPassword)
      setAlertBody(error.message)

      // Flag that the reset password is no longer being sent due to the exception
      setIsSendingCode(false)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault()
    setShowAlert(false)

    // Flag that the reset password has been sent and is confirming
    setIsConfirming(true)

    try {
      validateFormForErrorAlerting()

      // Submit the new password with verification code to using the Auth module from Amplify
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      )

      // Flag that the new password has now been confirmed
      setConfirmed(true)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToResetPassword)
      setAlertBody(error.message)

      // Flag that the reset password is no longer being confirmed due to the exception
      setIsConfirming(false)
    }
  }

  function renderRequestCodeForm() {
    return (
      <Form onSubmit={handleSendCodeClick}>
        <h3>Reset your password</h3>
        <FormGroup bsSize="large" controlId="email">
          <FormLabel>Email *</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            placeholder="Email"
          />
        </FormGroup>
        <br />
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isSendingCode}
          disabled={!validateCodeForm()}
        >
          Send Confirmation
        </LoaderButton>
      </Form>
    )
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmClick}>
        <h3>Confirm password reset</h3>
        <FormGroup bsSize="large" controlId="code">
          <FormLabel>Confirmation Code *</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
            placeholder="Confirmation Code"
          />
          <FormText className="text-muted">
            Please check your email ({fields.email}) for the confirmation code.
          </FormText>
        </FormGroup>
        <hr />
        <FormGroup bsSize="large" controlId="password">
          <FormLabel>New Password *</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            placeholder="Password"
          />
          <FormText className="text-muted">{passwordPolicy}</FormText>
        </FormGroup>
        <br />
        <FormGroup bsSize="large" controlId="confirmPassword">
          <FormLabel>Confirm Password *</FormLabel>
          <FormControl
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
            placeholder="Password"
          />
        </FormGroup>
        <br />
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isConfirming}
          disabled={!validateResetForm()}
        >
          Confirm
        </LoaderButton>
      </Form>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <b>Your password has been reset.</b>
        <p>
          <Link to="/signin">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    )
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>

      <div className="reset-password-div">
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}
      </div>
    </Container>
  )
}

export default ResetPassword
