import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import Premier from "./Premier";
// import PerfectPlan from "./PerfectPlan";
// import GetAccess from './GetAccess';

export class Home extends Component {
    render() {
        return (
            <div>
                <Container>
                    {/* <GetAccess /> */}
                    <Premier />
                </Container>
            </div>
        )
    }
}

export default Home;