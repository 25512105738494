import React from "react"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"

function MainNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" bg="white">
      <Navbar.Brand className="justify-content-center" href="/">
        <img
          alt="Logo"
          src="/logo.png"
          width="99"
          height="35"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav-main" />
      <Navbar.Collapse id="responsive-navbar-nav-main">
        <Nav className="mr-auto">
          <NavDropdown title="My Account" id="account-nav-dropdown">
            <NavDropdown.Item href="signin">Sign In</NavDropdown.Item>
            <NavDropdown.Item href="reset-password">Reset Password</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="signup">Sign Up</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavbar
