import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap"
import { Link } from "react-router-dom"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import "./Profile.css"

const Profile = props => {
  // Automatically executes on load
  useEffect(() => {
    document.title = "Boditel | Profile"
    fetchUserAttributes()
  }, [])

  // User Getter/Setters
  const [userAttributes, setUserAttributes] = useState([])
  const [email, setEmail] = useState("")
  const [emailVerified, setEmailVerified] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false)

  // Alert Getter/Setters
  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  // STRING CONSTANTS
  const profileNotCompleted = "Your profile is incomplete."
  const profileNotCompletedMissingPhone =
    "Please add a phone number to your account for increased security with MFA."
    const profileNotCompletedPhoneNotVerified =
      "Your phone number is not verified."

  // FUNCTIONS

  // Sets the user detail (email, phone) from AWS Amplify User
  async function fetchUserAttributes() {
    Auth.currentAuthenticatedUser().then(user => {
      // console.log("user email = " + user.attributes.email);
      console.log("Fetching user detail...")
      console.log(user.attributes)

      setUserAttributes(user.attributes)
      setEmail(user.attributes.email)
      setEmailVerified(user.attributes.email_verified)
      setPhoneNumber(user.attributes.phone_number)
      setPhoneNumberVerified(user.attributes.phone_number_verified)

      // checkProfileCompletion(user.attributes)
    })
  }
  // Checks the user's profile for completion (i.e. phone, billing)
  async function checkProfileCompletion(userAttributes) {
    console.log("Checking profile completion...")
    try {
      // Check if a phone is specified
      console.log(userAttributes)
      console.log(userAttributes.email)
      console.log(userAttributes.email_verified)
      console.log(userAttributes.phone_number)
      console.log(userAttributes.phone_number_verified)
      if (
        userAttributes.phone_number === "" ||
        userAttributes.phone_number == null
      )
        throw new Error(profileNotCompletedMissingPhone)
        if (
          userAttributes.phone_number_verified == false
        )
          throw new Error(profileNotCompletedPhoneNotVerified)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(profileNotCompleted)
      setAlertBody(error.message)
    }
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>

      <div className="profile-div">
        <Row>
          <Col>
            <h2>Account</h2>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs lg="4">
            <h4>Membership & Billing</h4>
            <br />
            <Button variant="danger">Cancel Membership</Button>
          </Col>
          <Col>
            <Row>
              <Col>
                <b>{email}</b>
                <br />
                <b>Password: ********</b>
                <br />
                <b>
                  Phone: {phoneNumber != "" ? phoneNumber : "Missing"} (
                  {phoneNumberVerified ? (
                    <b style={{ color: "green" }}>Verified</b>
                  ) : (
                      <Link style={{ color: "red" }} to="change-phone">
                        Verify Now
                      </Link>
                  )}
                  )
                </b>
                <br />
              </Col>
              <Col>
                <Link to="change-email">Change email</Link>
                <br />
                <Link to="change-password">Change password</Link>
                <br />
                <Link to="change-phone">Change phone</Link>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                •••• •••• •••• ••••
                <br />
                Your next billing date is MM DD, YYYY.
                <br />
              </Col>
              <Col>
                <Link to="#">Manage payment info</Link>
                <br />
                <Link to="#">Billing details</Link>
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs lg="4">
            <h4>Plan Details</h4>
            <br />
          </Col>
          <Col>
            <Row>
              <Col>
                <b>Internet Basic ($24.99/mo)</b>
              </Col>
              <Col>{/* <Link to="#">Change plan</Link> */}</Col>
            </Row>
          </Col>
        </Row>
        {/* <hr />
        <Row>
          <Col xs lg="4">
            <h4>Settings</h4>
            <br />
          </Col>
          <Col>
            <Link to="#">Activate a device</Link>
            <br />
            <Link to="#">Recent device streaming activity</Link>
            <br />
            <Link to="#">Download your personal information</Link>
            <br />
          </Col>
        </Row> */}
      </div>
    </Container>
  )
}

export default Profile
