// Contains common validation functions

export const passwordPolicy =
  "Requires 8 chars minimum and at least one uppercase letter, lowercase letter, special character and number."

export function hasNumber(str) {
  return /\d/.test(str)
}

export function hasSpecialCharacter(str) {
  var specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  return specialChars.test(str)
}

export function hasLowerCase(str) {
  return /[a-z]/.test(str)
}

export function hasUpperCase(str) {
  return /[A-Z]/.test(str)
}
