// This functional component asks a user to put in their new phone and click the Update Phone button.
// Cognito sends them a verification code. User receives the code via phone and then enters the code.
// Component then confirms that their phone has been changed.

import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Col,
  Row,
  Container,
  Form,
  FormText,
  FormGroup,
  FormControl,
  FormLabel,
  FormSelect,
} from "react-bootstrap"
import LoaderButton from "../../components/LoaderButton"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import { useHistory } from "react-router-dom"
import "./ChangePhone.css"

const ChangePhone = props => {
  useEffect(() => {
    document.title = "Change Phone"
  }, [])

  const history = useHistory()

  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  const [codeSent, setCodeSent] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    phoneCountryCode: "+1",
    phone: "",
  })
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  const failedToUpdatePhone = "Failed to update phone"
  const failedToConfirmCode = "Failed to confirm code"

  function validatePhoneForm() {
    return fields.phone.length > 0
  }

  function validateConfirmForm() {
    return fields.code.length > 0
  }

  // Performs additional form validation
  function validateFormForErrorAlerting(user) {
    // If the new phone matches the old phone, throw alert and exit validation
    if (fields.phone === user.attributes.phone) {
      throw new Error(
        "The new phone address cannot match the old phone address."
      )
    }
  }

  async function handleUpdateClick(event) {
    event.preventDefault()

    // Flag that the code is being sent
    setIsSendingCode(true)

    try {
      // Get the current user
      const currentUser = await Auth.currentAuthenticatedUser()

      validateFormForErrorAlerting(currentUser)

      // Update the user's phone using the Auth module from Amplify
      const formattedPhone =
        fields.phoneCountryCode +
        fields.phone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(".", "")
          .replaceAll(" ", "")
      console.log(formattedPhone)
      await Auth.updateUserAttributes(currentUser, {
        phone_number: formattedPhone,
      })
      // Flag that the code has now been sent via Amplify's Auth.updateUserAttributes()
      setCodeSent(true)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToUpdatePhone)
      setAlertBody(error.message)

      // Flag that the code is no longer being sent due to the exception
      setIsSendingCode(false)
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.verifyCurrentUserAttribute("phone_number")
      console.log("code resent successfully")
    } catch (err) {
      console.log("error resending code: ", err)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault()

    // Flag that the code is being confirmed
    setIsConfirming(true)

    try {
      // Confirms the phone change on Cognito’s side
      await Auth.verifyCurrentUserAttributeSubmit("phone_number", fields.code)

      // Redirect the user to the profile page
      history.push("/profile")

      // Refreshes the page to reflect change in phone to profile (TODO: change via state)
      window.location.reload(false)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToConfirmCode)
      setAlertBody(error.message)

      // Flag that the code is no longer being confirmed due to the exception
      setIsConfirming(false)
    }
  }

  // Renders an update form for the user to enter a new phone
  // Calls handleUpdateClick() upon submission
  function renderUpdateForm() {
    return (
      <Form onSubmit={handleUpdateClick}>
        <h3>Change your phone</h3>
        <Row className="mb-3">
          <FormGroup as={Col} controlId="phoneCountryCode">
            <FormLabel>Country Code</FormLabel>
            <FormControl readOnly type="phone" value={fields.phoneCountryCode} />
          </FormGroup>
          <FormGroup as={Col} controlId="phone">
            <FormLabel>Phone *</FormLabel>
            <FormControl
              autoFocus
              type="phone"
              value={fields.phone}
              onChange={handleFieldChange}
              placeholder="###-###-####"
            />
          </FormGroup>
        </Row>
        <br />
        <LoaderButton
          type="submit"
          size="large"
          isLoading={isSendingCode}
          disabled={!validatePhoneForm()}
        >
          Update Phone
        </LoaderButton>
      </Form>
    )
  }

  // Renders a confirm form for the user to enter the code sent to their phone
  // Calls handleConfirmClick() upon submission
  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmClick}>
        <h3>Confirm phone change</h3>
        <FormGroup size="large" controlId="code">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
            placeholder="Confirmation Code"
          />
          <FormText>
            Please check your phone ({fields.phoneCountryCode}{' '}
            {fields.phone}) for the confirmation code.
          </FormText>
        </FormGroup>
        <br />
        <LoaderButton
          type="submit"
          size="large"
          isLoading={isConfirming}
          disabled={!validateConfirmForm()}
        >
          Confirm
        </LoaderButton>{" "}
        <Button
          onClick={() => resendConfirmationCode}
          variant="outline-success"
        >
          Resend Code
        </Button>
        <br />
      </Form>
    )
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>
      <div className="change-phone-div">
        {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
      </div>
    </Container>
  )
}

export default ChangePhone
