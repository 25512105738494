import React from "react"
import {
  Accordion,
  useAccordionButton,
  Button,
  Card,
  Container,
  ListGroup,
} from "react-bootstrap"

function ContextAwareToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )

  return (
    <Button variant="primary" onClick={decoratedOnClick}>
      {children}
    </Button>
  )
}

function Privacy() {
  return (
    <Container>
      <p>Updated: <b>January 1, 2021</b></p>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="0">Summary</ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>
                <b>
                  In keeping with our commitment to be the most trusted provider
                  of communications and entertainment services ("Subscription
                  Services"), we are letting you know how we will collect, use,
                  and share your Personally Identifiable Information ("PII",
                  defined below) in connection with Subscription Services. We
                  are giving you clear, prior notice of, and in some
                  circumstances the right to choose, how your PII will be used
                  and shared as described below in this Residential Privacy
                  Notice ("Notice") or otherwise prior to or at the point of
                  collection of your PII. We have tried to explain our
                  Subscription Services data practices in clear and meaningful
                  detail in this Notice. We summarized each section of this
                  Notice below to make it quicker and easier for you to
                  understand what the full policy details; however the complete
                  policy, and not the headings or summaries govern, and we
                  encourage you to read those too.
                </b>
              </p>
              <p>
                <ListGroup>
                  <ListGroup.Item>
                    <b>Information We Collect</b>
                    <br />
                    We may collect Subscription Services PII (e.g., subscriber
                    name, service and mailing address, etc.), as well as other
                    information (e.g., information about services, general
                    location, demographics, etc.) and use it to provide you
                    services and as otherwise explained at Information We
                    Collect and Use below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Sharing</b>
                    <br />
                    We may share Subscription Services Personally Identifiable
                    Information (or give others access to it), for a variety of
                    purposes, as detailed in this Notice. These include, without
                    limitation:
                    <br />
                    - to operate, improve and market our Subscription Services,
                    and related business purposes as permitted or required by
                    applicable law or legal process;
                    <br />
                    - to tailor advertising to make it more relevant to your
                    household and to improve its effectiveness;
                    <br />
                    - in connection with corporate transactions (e.g., merger or
                    sale);
                    <br />
                    - in cooperation with law enforcement and legal requests
                    (More at Law Enforcement and Legal Requests below); and
                    <br />
                    - in connection with your use of third-party services (More
                    at Third-Party Services below).
                    <br />
                    <br />
                    More at Sharing below, including regarding how to opt-out,
                    where available.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Law Enforcement and Legal Requests</b>
                    <br />
                    We receive legal requests for Subscription Services PII from
                    government and law enforcement personnel. More at Law
                    Enforcement and Legal Requests below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Third-Party Services</b>
                    <br />
                    Our Subscription Services may include or link to Third-Party
                    Services. When you use Third-Party Services, Boditel merely
                    facilitates your access to those services, and you are
                    subject to the data collection and use practices and privacy
                    policies of the Third-Party Services. Boditel is not
                    responsible for their data practices so you are encouraged
                    to familiarize yourself with and consult their privacy
                    policies and terms of use. More at Third-Party Services
                    below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Security</b>
                    <br />
                    Maintaining the security of your personal computer is an
                    important way to protect your privacy and to help us protect
                    our network and customers. If you suspect that someone may
                    have made unauthorized changes to your account, contact
                    Customer Care at the telephone number on your bill. More at
                    Security below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Children's Privacy</b>
                    <br />
                    Our Subscription Services are intended for use by a general
                    audience and account holders must be of the age of majority
                    or emancipated minors. Boditel’s policies and practices
                    regarding children’s personal information, children’s and
                    minors’ privacy rights, and notice to parents of these
                    rights, are explained at Children's Privacy below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Other Terms and Changes in Policy</b>
                    <br />
                    Other terms and conditions affect our service offerings.
                    Changes in our service offerings or the law may cause us to
                    make changes to this and other policies from time to time.
                    More at Other Terms and Changes in Policy below.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Our Network Practices</b>
                    <br />
                    For more information about our network management practices,
                    please read our Internet Services Disclosures at
                    https://www.boditel.com/aboutus/policies/internet-service-disclosures.html.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Cable Television Protection of Subscriber Policy</b>
                    <br />
                    See Cable Television Protection of Subscriber Privacy below
                    for a summary of your privacy rights under federal law
                    relating to our television services.
                  </ListGroup.Item>
                </ListGroup>
                <br />
                Click on the links above to go to the full section to learn more
                or just keep scrolling. In addition, if you have any questions
                about this Notice, or the data practices associated with our
                Subscription Services, please contact Boditel at
                privacy@boditel.com.
                <br />
                <br />
                This Notice is limited to PII collected in connection with our
                Subscription Services. Other policies and notices may apply to
                other data collected outside of our Subscription Services. For
                example, see our Online Privacy Policy for our data practices
                related to websites and mobile apps that are not part of the
                Subscription Services. California residents have additional
                privacy rights that apply to our Subscription Services explained
                in our California Consumer Privacy Notice.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="1">
              Information We Collect and Use
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <p>
                Generally, we collect and use your Personally Identifiable
                Information to provide you services and as otherwise related to
                the operation of our business, as detailed below.
              </p>

              <p>
                <b>Your Personally Identifiable Information (PII)</b>
                <br />
                In providing our Subscription Services, we sometimes collect
                PII, such as subscriber name, service and mailing addresses,
                telephone numbers, social security number, driver's license
                number, email address, billing and payment records (including
                credit card and bank account numbers used to pay for our
                services), subscriber credit information, or other information
                that potentially could be used to identify, contact, or locate
                you ("Personally Identifiable Information", or "PII"). We may
                also collect demographic and usage information about you and
                other subscribers such as information about premium services,
                general location, demographics, the services you have chosen to
                receive, information about equipment and devices connected to
                our cable system and network, complaint information, including
                customer correspondence and communications records, and
                information regarding maintenance, repairs, services, equipment,
                software, usage, settings and preferences to aid in customer
                support and in recommendations for you. We may combine PII with
                demographic and other information for purposes consistent with
                this Notice. To the extent such other information is directly
                linked to PII, it will be considered PII. We and third parties
                may create aggregate and/or deidentified information (i.e.,
                information from which personally identifiable elements have
                been removed or modified), which is not considered PII in this
                Notice.
              </p>

              <p>
                <b>Internet Services</b>
                <br />
                Like most Internet service providers, we automatically collect
                and store Internet Protocol (IP) addresses (an identifier given
                to your connection while online), the volume of and types of
                data transmitted and received through your service, device types
                used, Internet connection performance, modem, gateway, and other
                device MAC addresses, and connection dates and times. We may
                also collect and store other usage statistics, such as the
                volume of data transmitted by certain protocols, devices, and
                services, to help us understand how our network is used, to give
                you insight into your household Internet usage, and to improve
                the Subscription Services we offer. We monitor our network and
                collect telemetry and other data to measure the quality of our
                Internet service, and to help us troubleshoot and diagnose
                service issues you are experiencing. We also monitor our network
                for security, malware, and fraud prevention purposes. If you are
                accessing the Internet away from your home through a Wi-Fi
                connection provided by Boditel or its partners, we may also
                collect the device MAC ID, device type, location of the access
                point, duration, and session information.
              </p>
              <p>
                We also provide location-based online advertising based on your
                general geographic area. Visit the preferences section in your
                My Account at www.boditel.com for more information and to opt
                out of receiving location-based ads.
              </p>

              <p>
                <b>Interest-Based Content Suggestions and Advertising</b>
                <br />
                We also combine TV Viewing Information with additional
                information we obtain from other sources, including, for
                example, from public databases, advertisers, and data providers,
                so we can tailor advertising to make it more relevant to your
                household and to improve its effectiveness, and to offer you
                content suggestions. If you prefer that we not show you targeted
                ads, you can opt-out by visiting the preferences center in My
                Account at www.boditel.com. Please note, if you opt-out you will
                still receive TV advertising from Boditel, but it will be
                generic and not targeted to your household’s interests.
              </p>

              <p>
                <b>Customer Proprietary Network Information</b>
                <br />
                For more information on how we treat customer proprietary
                network information, see the "Customer Proprietary Network
                Information" section below.
              </p>

              <p>
                <b>Communicating with You</b>
                <br />
                We may communicate with you online, including in browser
                notifications, concerning maintenance, security, and fraud
                prevention issues, as well as information from Boditel that you
                may be interested in. If you prefer, you may opt-out of
                marketing emails to a specific address by notifying us in
                response to the email you receive, through the "unsubscribe"
                link found at the bottom of the email. We may send
                communications through text messages to the extent you have
                opted in or as permitted by law.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="2">Sharing</ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <p>
                <b>Personally Identifiable Information (PII)</b>
                <br />
                We may use and share Subscription Services Personally
                Identifiable Information to operate and improve our Subscription
                Services, and related business purposes as permitted or required
                by applicable law or legal process, including, for example, for
                the following activities: sales, installation, training,
                operations, administration, advertising, marketing, support,
                development of new products and services, data analytics,
                network management, fraud prevention, identity verification,
                maintenance, customer care, communications with you, billing and
                collection. We may combine PII with demographic and other
                information for purposes consistent with this Notice. We take
                reasonable precautions to identify you or persons you have
                authorized when we are contacted about your Boditel account.
              </p>
              <p>
                <b>Internet Services and Email</b>
                <br />
                We may share network traffic data, including IP addresses, with
                trusted third parties who work to protect ISPs and the Internet
                from botnets and other threats.
              </p>
              <p>
                We don’t share the contents of your online communications,
                including emails, unless required by law or legal process. We
                may retain and provide such communications to others if we are
                legally required or compelled to do so. For the email accounts
                that are provided by us, we scan incoming and outgoing email
                traffic electronically to identify and filter out likely spam
                and for security and fraud prevention purposes.
              </p>
              <p>
                <b>Special Exceptions</b>
                <br />
                We reserve the right to disclose Personally Identifiable
                Information if we have a good faith belief it is necessary to:
                (1) comply with the law or legal process; (2) protect our
                network, rights, or property or those of others; (3) respond to
                fraud, abuse or unauthorized reception or access; (4) enforce
                our Policies posted on boditel.com; or (5) act in an emergency
                to protect your safety or that of another person. See also the
                "Law Enforcement and Legal Requests" section below. We may also
                disclose PII as follows: with your consent, as explained at the
                time of collection, or as otherwise not prohibited by applicable
                law and not inconsistent with this Notice or any notice given by
                us at the time of collection. Further, we may disclose and/or
                transfer Personally Identifiable Information in connection with
                or during negotiations of any proposed or actual financing of
                our business, or merger, purchase, sale, joint venture, or any
                other type of acquisition or business combination of all or any
                portion of Boditel assets, or transfer of all or a portion of
                Boditel’s business to another company, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding ("Corporate Transactions").
              </p>
              <p>
                We may also provide your Subscription Services Personally
                Identifiable Information to you or your authorized users or
                agents upon request, however, we take reasonable precautions to
                identify you, or persons you have authorized, when we are
                contacted about your Boditel account before doing so.
              </p>
              <p>
                <b>Vendors</b>
                <br />
                We use vendors in providing Subscription Services to you and may
                share PII with them for these purposes. For instance, we may use
                vendors to send you transactional, informational, marketing, or
                relationship messages related to your Subscription Services via
                email. We require these vendors to maintain reasonable security
                of PII they process for us.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="3">
              Law Enforcement and Legal Requests
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <p>
                <b>Information We Must Disclose</b>
                <br />
                We routinely receive legal requests for customer information
                from government and law enforcement personnel. We also receive
                discovery requests in civil cases. We cooperate by providing the
                information required by law or as compelled by legal process.
                Boditel does not volunteer Subscription Services Personally
                Identifiable Information, including access to customer
                communications, to law enforcement or others, except in the
                "Special Exceptions" detailed in the “Sharing” section above, or
                as otherwise disclosed in the Notice or at the point of
                collection (e.g., to our vendors in connection with their
                provision of services to us). Many criminal subpoenas require
                that we not disclose or notify you of the request. Due to this
                fact and the volume of requests we receive, we do not assume any
                duty to notify you of receipt of any legal requests. The
                following applies to certain types of your Subscription Services
                PII that may be the subject of law enforcement or legal request:
              </p>
              <p>
                <b>Internet Information</b>
                <br />
                We sometimes receive subpoenas for your identity in connection
                with use of your Internet service for apparent copyright
                infringement or other civil matters. Files shared over
                "peer-to-peer" services often include your IP address, and if we
                receive a subpoena, we will provide the Personally Identifiable
                Information associated with that IP address. Law enforcement can
                also obtain your Subscription Services Personally Identifiable
                Information, including details about your Internet use and the
                content of communications, from us through a warrant or similar
                authority.
              </p>
              <p>
                <b>Child Pornography</b>
                <br />
                We work closely with the National Center for Missing and
                Exploited Children and other groups to eliminate child
                pornography on the Internet. The law requires us to report any
                evidence of apparent child pornography and we may share your PII
                in connection therewith.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="4">
              Third-Party Services
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <p>
                Our Subscription Services may include or link to third-party
                websites, apps, ads, locations, platforms, code (e.g., plug-ins,
                application programming interfaces ("API"), and software
                development kits ("SDKs")), or other services ("Third-Party
                Service(s)"). These Third-Party Services may use their own
                cookies, web beacons, and other tracking technology to
                independently collect information about you and may solicit PII
                and other data from you.
              </p>
              <p>
                Third-Party Services include third-party content provider apps
                and links that enable you to access Third-Party Services via our
                Subscription Services ("Content Services"). When you access
                those Third-Party Services in this manner, you are interacting
                directly with the Third-Party Service even if you have not
                closed out of our Subscription Services. Examples include when
                you access any of the following content providers’ services
                while on our Subscription Services: Netflix, Pandora, Amazon
                Prime Video, and other third-party apps providing content. In
                addition, when accessing content networks directly via our
                Subscription Services, the content network programmers
                ("Third-Party Networks") may use SDKs and other technologies to
                directly collect information from and about you, in which case
                they are acting as Third-Party Services providers. Third-Party
                Services may also include third-party audience measurement
                services. We do not consider these third-party data collections
                to be a sale or disclosure by us.
              </p>
              <p>
                In addition, certain functionalities of our Subscription
                Services permit interactions that you initiate between our
                Subscription Services and certain Third-Party Services, such as
                third-party social networks ("Social Features"). Examples of
                Social Features include: "liking" or "sharing" Boditel’s
                content; logging in to our Subscription Services using your
                Third-Party Service account (e.g., using Facebook Connect to
                sign-in to our service); and to otherwise connect our
                Subscription Services to a Third-Party Service (e.g., to pull or
                push information to or from the service). If you use Social
                Features, and potentially other Third-Party Services,
                information you post or provide access to may be publicly
                displayed on our Subscription Services or by the Third-Party
                Service that you use. Also, both Boditel and the third-party may
                have access to certain information about you and your use of our
                Subscription Services and any Third-Party Service.
              </p>
              <p>
                When you use Third-Party Services, including Social Features,
                Content Services and Third-Party Networks, Boditel is merely
                facilitating your access to those services, and you are subject
                to the data collection and use practices and privacy policies of
                the Third-Party Services and Boditel is not responsible for
                their data practices. Boditel encourages you to familiarize
                yourself with and consult their privacy policies and terms of
                use.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="5">Security</ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <p>
                <b>Taking Proper Precautions</b>
                <br />
                Maintaining the security of your personal computer is an
                important way to protect your privacy and to help us protect our
                network and customers. Following our policies posted on
                www.boditel.com and maintaining your antivirus software,
                firewall, wireless network security, and your operating system
                can help prevent harm and potential theft of data. You should
                regularly back up your computer to preserve your files,
                including messages you want to keep, and change your login
                password regularly. Always be sure you know with whom you are
                dealing before clicking on an Internet link or giving personal
                information. When communications enter the Internet, it is
                possible for outside parties to access them. Since we cannot
                control websites or services operated by third parties, you
                should review their terms of service and privacy policies before
                use. We may take protective action related to your Internet
                service or contact you to help with security issues we identify,
                such as malware infections. Unsecured Wi-Fi home networks can
                allow unauthorized persons to use your Internet service for
                illegal activity which can be attributed to your account. We
                monitor our network and scan incoming and outgoing boditel.com
                email messages to filter out likely spam, harmful messages,
                viruses, malware, spyware, and related threats that could harm
                your equipment, the network, or others.
              </p>
              <p>
                <b>Spam</b>
                <br />
                We try to block incoming and outgoing spam using a variety of
                methods. You can help by preventing unauthorized access to your
                computer and email account. You can forward unwanted spam to
                spamreport@boditel.net and phishing scams to
                phishingreport@boditel.net to help update our filters. We will
                never ask you to send sensitive personal information to us by
                unsecured email.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="6">
              Children's Policy
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              Our Subscription Services are intended for use by a general
              audience and account holders must be of the age of majority or
              emancipated minors. We do not intend to or knowingly collect
              personal information (as the term is defined by the Children’s
              Online Privacy Protection Act ("COPPA")) from children under the
              age of 13. If you believe your child may have provided us with
              their personal information, you can contact Boditel at
              privacy@boditel.com and we will delete their personal information
              to the extent required by COPPA. We treat information concerning
              Subscription Services provided for family use as that of the
              account holder. You can find more information about protecting
              children's privacy by clicking on "Take Charge" on boditel.com or
              visiting https://www.ftc.gov.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="7">
              Other Terms and Changes in Policy
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              Other terms and conditions affect our service offerings, including
              the online privacy policies for our websites and apps, service
              contracts, our Acceptable Use Policy for High Speed Internet
              service, tariffs, and the terms of use for our websites and apps.
              We intend this Notice to supplement these documents in connection
              with the Subscription Services. Changes in our service offerings
              or the law may cause us to make changes to this Notice and other
              policies from time to time. We will post any changes at
              boditel.com, along with the effective date of the changes, so
              check the notices center of our website often.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="8">
              Our Network Practices
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              <p>
                For more information about our network management practices,
                please read our Internet Services Disclosures at
                https://www.boditel.com/aboutus/policies/internet-service-disclosures.html.
              </p>
              <p>
                <b>Internet Services and Email</b>
                <br />
                Unless addressed to us and required by law or legal process, we
                do not read the contents of your online communications, such as
                email. We may, however, retain and provide such communications
                if we are legally required to do so. For the email accounts that
                are provided by us, we generally scan incoming and outgoing
                email traffic electronically to identify and filter out likely
                spam and for security and fraud prevention purposes. We may use
                email to send transactional, informational, or relationship
                messages related to your Subscription Services. If you prefer,
                you may opt-out of marketing emails to a specific address by
                notifying us in response to the email you receive, through the
                "unsubscribe" link found at the bottom of the email. We monitor
                our network for security and fraud prevention purposes. We may
                communicate with you online, including in browser notifications,
                concerning maintenance, security and fraud prevention issues, as
                well as offerings from Boditel that you may be interested in. We
                also provide location-based online advertising based on your
                general geographic area and nine-digit ZIP code, similar to
                direct mail you receive through the U.S. Mail. Visit
                Location-Based Advertising.
              </p>
              <p>
                <b>Special Exceptions</b>
                <br />
                We reserve the right to use and/or disclose Personally
                Identifiable Information if we have a good faith belief it is
                necessary to: (1) comply with the law or legal process; (2)
                protect our network, rights or property or those of others; (3)
                respond to fraud, abuse or unauthorized reception or access; (4)
                enforce our Policies posted on boditel.com; or (5) act in an
                emergency to protect your safety or that of another person. See
                also the "Law Enforcement and Legal Requests" section below. We
                may also use and/or disclose PII as you may consent, as
                explained at the time of collection, or as otherwise not
                prohibited by applicable law and not inconsistent with this
                Notice or any notice given by us at the time of collection.
                Further, we may disclose and/or transfer PII in connection with
                or during negotiations of any proposed or actual financing of
                our business, or merger, purchase, sale, joint venture, or any
                other type of acquisition or business combination of all or any
                portion of Boditel assets, or transfer of all or a portion of
                Boditel’s business to another company, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding ("Corporate Transactions").
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  )
}

export default Privacy
