import React, { Component } from "react"
import { Nav, Navbar } from "react-bootstrap"

import FooterStyle from "./Footer.css"

export class Footer extends Component {
  render() {
    return (
      <div>
        <br />
        <Navbar
          collapseOnSelect
          className="nav-bar"
          expand="md"
          style={FooterStyle}
        >
          <Navbar.Brand className="justify-content-center">
            <div className="nav-div">Boditel © 2021</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav-main" />
          <Navbar.Collapse id="responsive-navbar-nav-main">
            {/* <Nav.Link href="/faq">FAQ</Nav.Link>
            <Nav.Link href="/support">Help Center</Nav.Link> */}
            <Nav.Link href="/terms">Terms of Use</Nav.Link>
            <Nav.Link href="/privacy">Privacy</Nav.Link>
            {/* <Nav.Link href="/contact">Contact Us</Nav.Link> */}
            <Nav.Link href="/about">About Us</Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Footer
