import React, { useState, useEffect } from "react"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import Auth from "@aws-amplify/auth"

const MainNavbarSignedIn = props => {
  const [userAttributes, setuserAttributes] = useState([])

  // Automatically executes on load
  useEffect(() => {
    return fetchuserAttributes()
  }, [])

  // FUNCTIONS

  // Sets the user detail (email, phone) from AWS Amplify User
  async function fetchuserAttributes() {
    Auth.currentAuthenticatedUser().then(user => {
      // console.log("user email = " + user.attributes.email);
      setuserAttributes(user.attributes)
    })
  }

  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" bg="white">
      <Navbar.Brand className="justify-content-center" href="/">
        <img
          alt="Logo"
          src="/logo.png"
          width="99"
          height="35"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav-main" />
      <Navbar.Collapse id="responsive-navbar-nav-main">
        <Nav className="mr-auto">
          <Navbar.Brand href="alerts">
            <img
              alt=""
              src="/icons/alert.png"
              width="31"
              height="28"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <NavDropdown title="My Account" id="account-nav-dropdown">
            <NavDropdown.Item href="profile">
              {userAttributes.email}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="change-email">
              Change Email
            </NavDropdown.Item>
            <NavDropdown.Item href="change-password">
              Change Password
            </NavDropdown.Item>
            <NavDropdown.Item href="change-phone-number">
              Change Phone Number
            </NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="manage-payment-info">
              Manage Payment Info
            </NavDropdown.Item>
            <NavDropdown.Item href="billing-details">
              Billing Details
            </NavDropdown.Item>
            <NavDropdown.Item href="change-billing-day">
              Change Billing Day
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="redeem">Redeem Promo Code</NavDropdown.Item> */}
            <NavDropdown.Divider />
            <NavDropdown.Item href="signout">Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavbarSignedIn
