// This functional component asks a user to put in their new email and click the Update Email button.
// Cognito sends them a verification code. User receives the code via email and then enters the code.
// Component then confirms that their email has been changed.

import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Form,
  FormText,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap"
import LoaderButton from "../../components/LoaderButton"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import { useHistory } from "react-router-dom"
import "./ChangeEmail.css"

const ChangeEmail = props => {
  useEffect(() => {
    document.title = "Change Email"
  }, [])

  const history = useHistory()

  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  const [codeSent, setCodeSent] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
  })
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  const failedToUpdateEmail = "Failed to update email"
  const failedToConfirmCode = "Failed to confirm code"

  function validateEmailForm() {
    return fields.email.length > 0
  }

  function validateConfirmForm() {
    return fields.code.length > 0
  }

  // Performs additional form validation
  function validateFormForErrorAlerting(user) {
    // If the new email matches the old email, throw alert and exit validation
    if (fields.email === user.attributes.email) {
      throw new Error(
        "The new email address cannot match the old email address."
      )
    }
  }

  async function handleUpdateClick(event) {
    event.preventDefault()

    // Flag that the code is being sent
    setIsSendingCode(true)

    try {
      // Get the current user
      const currentUser = await Auth.currentAuthenticatedUser()

      validateFormForErrorAlerting(currentUser)

      // Update the user's email using the Auth module from Amplify
      await Auth.updateUserAttributes(currentUser, { email: fields.email })
      // Flag that the code has now been sent via Amplify's Auth.updateUserAttributes()
      setCodeSent(true)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToUpdateEmail)
      setAlertBody(error.message)

      // Flag that the code is no longer being sent due to the exception
      setIsSendingCode(false)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault()

    // Flag that the code is being confirmed
    setIsConfirming(true)

    try {
      // Confirms the email change on Cognito’s side
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code)

      // Redirect the user to the profile page
      history.push("/profile")

      // Refreshes the page to reflect change in email to profile (TODO: change via state)
      window.location.reload(false)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToConfirmCode)
      setAlertBody(error.message)

      // Flag that the code is no longer being confirmed due to the exception
      setIsConfirming(false)
    }
  }

  // Renders an update form for the user to enter a new email
  // Calls handleUpdateClick() upon submission
  function renderUpdateForm() {
    return (
      <Form onSubmit={handleUpdateClick}>
        <h3>Change your email</h3>
        <FormGroup size="large" controlId="email">
          <FormLabel>Email *</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            placeholder="Email"
          />
        </FormGroup>
        <br />
        <LoaderButton
          type="submit"
          size="large"
          isLoading={isSendingCode}
          disabled={!validateEmailForm()}
        >
          Update Email
        </LoaderButton>
      </Form>
    )
  }

  // Renders a confirm form for the user to enter the code sent to their email
  // Calls handleConfirmClick() upon submission
  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmClick}>
        <h3>Confirm email change</h3>
        <FormGroup size="large" controlId="code">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
            placeholder="Confirmation Code"
          />
          <FormText>
            Please check your email ({fields.email}) for the confirmation code.
          </FormText>
        </FormGroup>
        <LoaderButton
          type="submit"
          size="large"
          isLoading={isConfirming}
          disabled={!validateConfirmForm()}
        >
          Confirm
        </LoaderButton>
      </Form>
    )
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>
      <div className="change-email-div">
        {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
      </div>
    </Container>
  )
}

export default ChangeEmail
